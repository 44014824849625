import Link from 'next/link';

import React from 'react';
import { IoIosAdd } from 'react-icons/io';

import style from './cardVerMaisCarousel.module.scss';

export default function CardVerMais({ url }) {
  return (
    <div className={style.seeMoreLancamentos}>
      <Link
        href={url}
        onKeyDown={(event) => event.key === 'Enter' && event.currentTarget.click()}
        tabIndex={0}>
        <a>
          <p>
            <IoIosAdd size={90} />
          </p>
          <h3>Ver mais</h3>
        </a>
      </Link>
    </div>
  );
}
